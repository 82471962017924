<template>
    <div class="flex">
        <aside class="card w-84 self-start mr-4">
            <h1>
                {{ $t('settings.menu') }}
            </h1>
            <ul class="py-4">
                <li class="py-1" :class="{ 'pl-4': activeMenu !== 'general' }">
                    <router-link :to="{ name: 'settings_economy_general' }" class="flex py-3 items-center" :class="{ 'active': activeMenu == 'general'}">
                        <fa-icon :icon="['far', 'chart-network']" fixed-width class="text-gray-600" />
                        <p class="text-gray-800 text-sm ml-2">
                            {{ $t('settings.general') }}
                        </p>
                    </router-link>
                </li>
                <li class="py-1" :class="{ 'pl-4': activeMenu !== 'payment_methods' }">
                    <router-link
                        :to="{ name: 'settings_economy_accepted_payment_methods' }"
                        class="flex py-3 items-center"
                        :class="{ 'active': activeMenu == 'payment_methods'}"
                    >
                        <fa-icon :icon="['far', 'credit-card']" fixed-width class="text-gray-600" />
                        <p class="text-gray-800 text-sm ml-2">
                            {{ $t('settings.accepted_payment_methods') }}
                        </p>
                    </router-link>
                </li>
                <li class="py-1" :class="{ 'pl-4': activeMenu !== 'invoice_logo' }">
                    <router-link :to="{ name: 'settings_economy_invoice_logo' }" class="flex py-3 items-center" :class="{ 'active': activeMenu == 'invoice_logo'}">
                        <fa-icon :icon="['far', 'draw-polygon']" fixed-width class="text-gray-600" />
                        <p class="text-gray-800 text-sm ml-2">
                            {{ $t('settings.invoice_logo') }}
                        </p>
                    </router-link>
                </li>
                <li class="py-1" :class="{ 'pl-4': activeMenu !== 'fortnox_integration' }">
                    <router-link :to="{ name: 'settings_economy_fortnox_integration' }" class="flex py-3 items-center" :class="{ 'active': activeMenu == 'fortnox_integration'}">
                        <fa-icon :icon="['far', 'chart-network']" fixed-width class="text-gray-600" />
                        <p class="text-gray-800 text-sm ml-2">
                            {{ $t('settings.fortnox_integration') }}
                        </p>
                    </router-link>
                </li>
            </ul>
        </aside>
        <transition mode="out-in">
            <router-view @activeSubpage="changeActiveMenu" />
        </transition>
    </div>
</template>
<script>
export default {
    data() {
        return {
            activeMenu: 'general',
        };
    },

    created() {
        this.$store.commit('setPageTitle', this.$t('settings.settings_economy'));
        this.$store.commit('setActiveMenuItem', '99-3');
    },

    methods: {
        changeActiveMenu(activeMenu) {
            this.activeMenu = activeMenu;
        },
    },
};
</script>
<style scoped>
    .menu {
        width: 350px;
        margin-right: 15px;
    }

    .active {
        @apply bg-gray-200 px-4 py-3 rounded relative;
        transition: 250ms ease background-color, 250ms ease color;
    }

    .active svg{
        color: #f26364 !important;
    }

    .active p {
        @apply font-semibold;
        color: #f26364 !important;
    }
</style>
